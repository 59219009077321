jQuery(document).ready(function($){

    function tile_resize() {
        $(this).css("height", "auto");
        var width = $(this).width();
        $(this).height(width);
    }

    function resize_tiles() {
        $(".shape-square").each(tile_resize);
    }

    resize_tiles();

    $(window).resize(Foundation.util.throttle(resize_tiles, 200));

    $('body').on('DOMSubtreeModified', '.shape-square', Foundation.util.throttle(tile_resize, 200));

});
