jQuery(document).ready(function($){

    $('.home-slider').slick({
        dots: true,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: false,
        fade: true,
    });

    $('.product__slider--main').slick({
        dots: false,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: false,
        prevArrow: '<button type="button" class="slick-prev"><i class="icon-arrow-left-alt"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="icon-arrow-right-alt"></i></button>',
        fade: true,
        responsive: [
            {
                breakpoint: 640,
                settings: {
                    arrows: true,
                },
            },
        ],
    });

    $('.product__slider--nav').slick({
        dots: false,
        speed: 1000,
        infinite: true,
        autoplay: false,
        cssEase: 'ease-out',
        arrows: false,
        fade: false,
        vertical: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: false,
        focusOnSelect: true,
        asNavFor: '.product__slider--main',
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    vertical: false,
                }
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    vertical: false,
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    vertical: false,
                }
            }
        ],
    });


});
